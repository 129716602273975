import { URL_LOGIN } from "../constants/UrlConstants";
import { apiRequest } from "../util/ServiceUtils";
import { createLog } from "../util/Util";

export const Authenticate = (body: object) => {
    return new Promise(async (resolve, reject) => {
        
        await apiRequest('POST', URL_LOGIN, body)
        .then((res) => {
            resolve(res)
        })
        .catch((err) => {
            createLog(err)
            reject(err)
        })
    });
}