// Pages
export const pageHome = '/';
export const pageLogin = '/login';
export const pageCandidateRegistration = '/candidate-registration';
export const pageLanding = '/';
export const pageLogout = '/logout';
export const pageProfile = '/me';

//
export const ssw = [
  'Nursing Care',
  'Building Cleaning Management',
  'Construction Industry (Jobs constructing houses, buildings, and other structures)',
  'Machine Parts and Tooling Industries',
  'Industrial Machinery Industry',
  'Electric, Electronics and Information Industries',
  'Ship building and Ship MAchinery Industries (Building Ships)',
  'Automobile and Repair Industries',
  'Aviation Industry',
  'Accommodation Industry',
  'Agriculture Industry',
  'Fishery and Agriculture Industries',
  'Food and Beverage Manufacturing Industries',
  'Food Service Industry',
];

export const JOB_SEARCH_ITEM_PER_PAGE = 10;
