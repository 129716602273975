import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Input,
  Text,
  Select,
  Button,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import useMergeState from '../../hooks/useMergeState';
import { valueIsEmail, valueIsEmpty } from '../../../util/Util';
import { ssw } from '../../../constants/StringConstants';
import CountryData from 'country-codes-list';

interface personalDataProps {
  nextStep: () => void;
  setPersonalData: (personalData: {}) => void;
  personalData?: any;
}

const PersonalDataReg = ({
  nextStep,
  setPersonalData,
  personalData,
}: personalDataProps) => {
  const countries = CountryData.all().sort((a, b) => {
    const nameA = a.countryNameEn.toUpperCase();
    const nameB = b.countryNameEn.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  const { t } = useTranslation();
  const [state, setState] = useMergeState({
    username: personalData ? personalData.username : '',
    password: personalData ? personalData.password : '',
    firstName: personalData ? personalData.firstName : '',
    fullName: personalData ? personalData.fullName : '',
    fullNameInJp: personalData ? personalData.fullNameInJp : '',
    lastName: personalData ? personalData.lastName : '',
    countryCode: personalData ? personalData.countryCode : '',
    contactNo: personalData ? personalData.contactNo : '',
    country: personalData ? personalData.country : '',
    nationality: personalData ? personalData.nationality : '',
    dob: personalData ? personalData.dob : '',
    address: personalData ? personalData.address : '',
    postalCode: personalData ? personalData.postalCode : '',
    gender: personalData ? personalData.gender : '',
    periodofStay: personalData ? personalData.periodofStay : '',
    statusOfResidence: personalData ? personalData.statusOfResidence : '',
    japaneseProficiency: personalData ? personalData.japaneseProficiency : '',
    jlptComplatedOn: personalData ? personalData.jlptComplatedOn : '',
    ssw: personalData ? personalData.ssw : '',
    firstNameError: '',
    lastNameError: '',
    countryError: '',
    contactError: '',
    userNameError: '',
    passwordError: '',
    nationalityError: '',
    dobError: '',
    addressError: '',
    genderError: '',
  });

  const validateInputs = () => {
    let isValid = true;
    if (valueIsEmpty(state.firstName)) {
      setState({ firstNameError: 'required' });
      isValid = false;
    } else {
      setState({ firstNameError: '' });
    }
    if (valueIsEmpty(state.fullName)) {
      setState({ fullNameError: 'required' });
      isValid = false;
    } else {
      setState({ fullNameError: '' });
    }
    if (valueIsEmpty(state.fullNameInJp)) {
      setState({ fullNameInJpError: 'required' });
      isValid = false;
    } else {
      setState({ fullNameInJpError: '' });
    }
    if (valueIsEmpty(state.country)) {
      setState({ countryError: 'required' });
      isValid = false;
    } else {
      setState({ countryError: '' });
    }
    if (valueIsEmpty(state.contactNo)) {
      setState({ contactError: 'required' });
      isValid = false;
    } else {
      setState({ contactError: '' });
    }
    if (valueIsEmpty(state.username)) {
      setState({ userNameError: 'required' });
      isValid = false;
    } else if (!valueIsEmail(state.username)) {
      setState({ userNameError: 'required' });
      isValid = false;
    } else {
      setState({ userNameError: '' });
    }
    if (valueIsEmpty(state.password)) {
      setState({ passwordError: 'required' });
      isValid = false;
    } else {
      setState({ passwordError: '' });
    }
    if (valueIsEmpty(state.nationality)) {
      setState({ nationalityError: 'required' });
      isValid = false;
    } else {
      setState({ nationalityError: '' });
    }
    if (valueIsEmpty(state.dob)) {
      setState({ dobError: 'required' });
      isValid = false;
    } else {
      setState({ dobError: '' });
    }
    if (valueIsEmpty(state.address)) {
      setState({ addressError: 'required' });
      isValid = false;
    } else {
      setState({ addressError: '' });
    }
    if (valueIsEmpty(state.postalCode)) {
      setState({ postalCodeError: 'required' });
      isValid = false;
    } else {
      setState({ postalCodeError: '' });
    }
    if (valueIsEmpty(state.gender)) {
      setState({ genderError: 'required' });
      isValid = false;
    } else {
      setState({ genderError: '' });
    }
    return isValid;
  };

  // after form submit validating the form data using validator
  const submitFormData = (e: any) => {
    e.preventDefault();
    console.log(state);
    if (validateInputs()) {
      const personalInfo = {
        username: state.username,
        password: state.password,
        firstName: state.firstName,
        fullName: state.fullName,
        fullNameInJp: state.fullNameInJp,
        lastName: state.fullName.split(' ').pop(),
        email: state.username,
        countryCode: state.countryCode,
        contactNo: state.contactNo,
        country: state.country,
        nationality: state.nationality,
        dob: state.dob,
        address: state.address,
        postalCode: state.postalCode,
        gender: state.gender,
        periodofStay: state.periodofStay,
        statusOfResidence: state.statusOfResidence,
        japaneseProficiency: state.japaneseProficiency,
        jlptComplatedOn: state.jlptComplatedOn,
        ssw: state.ssw,
      };
      setPersonalData(personalInfo);
      nextStep();
    }
  };

  return (
    <Box className="personal-infomation">
      <Heading>{t('titles.personalInformation')}</Heading>
      <Flex direction={['column', 'row']}>
        <Box flex={1}>
          <Text>{t('placeHolders.firstName')}</Text>
          <Input
            className="text-fields"
            placeholder={t('placeHolders.firstName')}
            size="md"
            value={state.firstName}
            onChange={(e) => setState({ firstName: e.target.value })}
          />
          {state.firstNameError !== '' ? (
            <Text className="input-error-message">
              {t(state.firstNameError)}
            </Text>
          ) : (
            <></>
          )}
        </Box>
        <Box flex={1}></Box>
      </Flex>

      <Flex direction={'column'}>
        <Box flex={1}>
          <Text>{t('Full Name (English)')}</Text>
          <Input
            className="text-fields"
            placeholder={t('Full Name (English)')}
            size="md"
            value={state.fullName}
            onChange={(e) => setState({ fullName: e.target.value })}
          />
        </Box>
        <Box flex={1}>
          <Text>{t('Full Name (Japanese)')}</Text>
          <Input
            className="text-fields"
            placeholder={t('Full Name (Japanese)')}
            size="md"
            value={state.fullNameInJp}
            onChange={(e) => setState({ fullNameInJp: e.target.value })}
          />
          {state.fullNameInJpError !== '' ? (
            <Text className="input-error-message">
              {t(state.fullNameInJpError)}
            </Text>
          ) : (
            <></>
          )}
        </Box>
      </Flex>

      <Flex direction={['column', 'row']}>
        <Box flex={1}>
          <Text>{t('placeHolders.nationality')}</Text>
          <Input
            className="text-fields"
            placeholder={t('placeHolders.nationality')}
            size="md"
            value={state.nationality}
            onChange={(e) => setState({ nationality: e.target.value })}
          />
          {state.nationalityError !== '' ? (
            <Text className="input-error-message">
              {t(state.nationalityError)}
            </Text>
          ) : (
            <></>
          )}
        </Box>
        <Box flex={1} ml={[0, '10px']}>
          <Text>{t('placeHolders.dob')}</Text>
          <Input
            type="date"
            className="text-fields"
            placeholder={t('placeHolders.dob')}
            value={state.dob}
            size="md"
            onChange={(e) => setState({ dob: e.target.value })}
          />
          {state.dobError !== '' ? (
            <Text className="input-error-message">{t(state.dobError)}</Text>
          ) : (
            <></>
          )}
        </Box>
      </Flex>

      <Flex direction={['column', 'row']}>
        <Box flex={2}>
          <Text>{t('placeHolders.address')}</Text>
          <Input
            className="text-fields"
            placeholder={t('placeHolders.address')}
            size="md"
            value={state.address}
            onChange={(e) => setState({ address: e.target.value })}
          />
          {state.addressError !== '' ? (
            <Text className="input-error-message">{t(state.addressError)}</Text>
          ) : (
            <></>
          )}
        </Box>
        <Box flex={1} ml={[0, '10px']}>
          <Text>{t('Postal Code')}</Text>
          <Input
            className="text-fields"
            placeholder={t('Postal Code')}
            size="md"
            value={state.postalCode}
            onChange={(e) => setState({ postalCode: e.target.value })}
          />
          {state.postalCodeError !== '' ? (
            <Text className="input-error-message">
              {t(state.postalCodeError)}
            </Text>
          ) : (
            <></>
          )}
        </Box>
        <Box flex={1} ml={[0, '10px']}>
          <Text>{t('placeHolders.country')}</Text>
          <Select
            className="text-fields"
            placeholder={t('placeHolders.country')}
            value={state.country}
            onChange={(e) => setState({ country: e.target.value })}
          >
            {countries.map((country) => (
              <option value={country.countryNameEn}>
                {t(country.countryNameEn)}
              </option>
            ))}
          </Select>
          {state.countryError !== '' ? (
            <Text className="input-error-message">{t(state.countryError)}</Text>
          ) : (
            <></>
          )}
        </Box>
      </Flex>

      <Flex direction={['column', 'row']}>
        <Box flex={1} ml={[0, '0']}>
          <Text>{t('placeHolders.gender')}</Text>
          <Select
            className="text-fields"
            placeholder={t('placeHolders.gender')}
            value={state.gender}
            onChange={(e) => setState({ gender: e.target.value })}
          >
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </Select>
          {state.genderError !== '' ? (
            <Text className="input-error-message">{t(state.genderError)}</Text>
          ) : (
            <></>
          )}
        </Box>
        <Box flex={1} ml={[0, '10px']}>
          <Flex>
            <Box flex={1}>
              <Text>{t('placeHolders.code')}</Text>
              <Select
                className="text-fields"
                value={state.countryCode}
                onChange={(e) => setState({ countryCode: e.target.value })}
              >
                {countries.map((country) => (
                  <option value={country.countryCallingCode}>
                    {country.countryCallingCode} - {t(country.countryNameEn)}
                  </option>
                ))}
              </Select>
            </Box>
            <Box flex={[1, 2]} ml="10px">
              <Text>{t('placeHolders.contactNo')}</Text>
              <Input
                type="number"
                className="text-fields"
                placeholder={t('placeHolders.contactNo')}
                size="md"
                value={state.contactNo}
                onChange={(e) => setState({ contactNo: e.target.value })}
              />
            </Box>
          </Flex>
          {state.contactError !== '' ? (
            <Text className="input-error-message">{t(state.contactError)}</Text>
          ) : (
            <></>
          )}
        </Box>
      </Flex>

      <Flex direction={['column', 'row']}>
        <Box flex={1}>
          <Text>{t('placeHolders.japaneseProficiency')}</Text>
          <Select
            className="text-fields"
            placeholder={t('placeHolders.japaneseProficiency')}
            value={state.japaneseProficiency}
            onChange={(e) => setState({ japaneseProficiency: e.target.value })}
          >
            <option value="6">{t('None')}</option>
            <option value="5">N5</option>
            <option value="4">N4</option>
            <option value="3">N3</option>
            <option value="2">N2</option>
            <option value="1">N1</option>
          </Select>
        </Box>
        <Box flex={1} ml={[0, '10px']}>
          <Text>{t('placeHolders.jlptComplatedOn')}</Text>
          <Input
            type="date"
            className="text-fields"
            placeholder={t('placeHolders.jlptComplatedOn')}
            value={state.jlptComplatedOn}
            size="md"
            onChange={(e) => setState({ jlptComplatedOn: e.target.value })}
          />
        </Box>
      </Flex>

      <Flex direction={['column', 'row']}>
        <Box flex={1}>
          <Text>{t('placeHolders.periodofStay')}</Text>
          <Input
            className="text-fields"
            placeholder={t('placeHolders.periodofStay')}
            size="md"
            value={state.periodofStay}
            onChange={(e) => setState({ periodofStay: e.target.value })}
          />
        </Box>
        <Box flex={1} ml={[0, '10px']}>
          <Text>{t('placeHolders.statusOfResidence')}</Text>
          <Input
            className="text-fields"
            placeholder={t('placeHolders.statusOfResidence')}
            value={state.statusOfResidence}
            size="md"
            onChange={(e) => setState({ statusOfResidence: e.target.value })}
          />
        </Box>
      </Flex>

      <Flex direction={['column', 'row']}>
        <Box flex={1}>
          <Text>{t('placeHolders.ssw')}</Text>
          <Select
            className="text-fields"
            placeholder={t('placeHolders.ssw')}
            value={state.ssw}
            onChange={(e) => setState({ ssw: e.target.value })}
          >
            {ssw.map((item: any) => (
              <option value={item}>{item}</option>
            ))}
          </Select>
        </Box>
        <Box flex={1} ml="10px"></Box>
      </Flex>

      <Heading mt={10}>{t('titles.accountInformation')}</Heading>
      <Flex direction={['column', 'row']}>
        <Box flex={1}>
          <Text>{t('placeHolders.email')}</Text>
          <Input
            className="text-fields"
            placeholder={t('placeHolders.email')}
            size="md"
            value={state.username}
            onChange={(e) => setState({ username: e.target.value })}
          />
          {state.userNameError !== '' ? (
            <Text className="input-error-message">
              {t(state.userNameError)}
            </Text>
          ) : (
            <></>
          )}
        </Box>
        <Box flex={1} ml={[0, '10px']}>
          <Text>{t('placeHolders.password')}</Text>
          <Input
            type="password"
            className="text-fields"
            placeholder={t('placeHolders.password')}
            value={state.password}
            size="md"
            onChange={(e) => setState({ password: e.target.value })}
          />
          {state.passwordError !== '' ? (
            <Text className="input-error-message">
              {t(state.passwordError)}
            </Text>
          ) : (
            <></>
          )}
        </Box>
      </Flex>

      <Flex mb={20}>
        <Box flex={1} mr="10px"></Box>
        <Box alignItems="center" justifyContent="center" flex={1} ml="10px">
          <Flex justify="flex-end">
            <Button
              mt="20px"
              className="button-primary"
              onClick={submitFormData}
            >
              {t('buttons.next')}
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default PersonalDataReg;
