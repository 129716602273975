import React from 'react';
import { useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Input,
  Text,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Select,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import useMergeState from '../../hooks/useMergeState';
import { valueIsEmpty } from '../../../util/Util';
import {
  EduInfoObject,
  EducationInstituteClassicationEnum,
} from '../../types/EduInfoObject';

interface educationalDataProps {
  nextStep: () => void;
  prevStep: () => void;
  setEducationalData: (educationalData: EduInfoObject[]) => void;
  educationalData: EduInfoObject[];
}

const EducationalDataReg = ({
  nextStep,
  prevStep,
  setEducationalData,
  educationalData,
}: educationalDataProps) => {
  const { t } = useTranslation();
  const [state, setState] = useMergeState({
    institute: '',
    qualification: '',
    instituteClassication: 'JuniorCollege',
    startDate: '',
    endDate: '',
    instituteError: '',
    qualificationError: '',
    startDateError: '',
  });
  const [educationalInfo, setEducationalInfo] = useState(educationalData);

  // after form submit validating the form data using validator
  const submitFormData = (e: any) => {
    e.preventDefault();
    if (educationalInfo.length > 0) {
      setEducationalData(educationalInfo);
      nextStep();
    }
  };

  const validateInputs = () => {
    let isValid = true;
    if (valueIsEmpty(state.institute)) {
      setState({ instituteError: 'required' });
      isValid = false;
    } else {
      setState({ instituteError: '' });
    }
    if (valueIsEmpty(state.qualification)) {
      setState({ qualificationError: 'required' });
    } else {
      setState({ qualificationError: '' });
    }
    if (valueIsEmpty(state.instituteClassication)) {
      setState({
        instituteClassicationError: 'required',
      });
      isValid = false;
    } else {
      setState({ instituteClassicationError: '' });
    }
    if (valueIsEmpty(state.startDate)) {
      setState({ startDateError: 'required' });
      isValid = false;
    } else {
      setState({ startDateError: '' });
    }
    return isValid;
  };

  const addOnClick = () => {
    if (validateInputs()) {
      const eduInfo = {
        institute: state.institute,
        qualification: state.qualification,
        startDate: state.startDate,
        endDate: state.endDate,
        isReading: true,
        instituteClassication: state.instituteClassication,
      };
      educationalInfo.push(eduInfo);
      setEducationalInfo(educationalInfo);
      setState({
        institute: '',
        qualification: '',
        startDate: '',
        endDate: '',
        instituteClassication: 'JuniorCollege',
      });
    }
  };

  const removeOnClick = (item: EduInfoObject) => {
    const arr = educationalInfo.filter((x) => x !== item);
    setEducationalInfo(arr);
  };

  return (
    <>
      <Heading>{t('titles.educationalInformtion')}</Heading>
      <Flex direction={['column', 'row']}>
        <Box flex={1}>
          <Text>{t('placeHolders.qualification')}</Text>
          <Input
            className="text-fields"
            placeholder={t('placeHolders.qualification')}
            size="md"
            value={state.qualification}
            onChange={(e) => setState({ qualification: e.target.value })}
          />
          {state.qualificationError !== '' ? (
            <Text className="input-error-message">
              {t(state.qualificationError)}
            </Text>
          ) : (
            <></>
          )}
        </Box>
        <Box flex={1} ml={[0, '10px']}>
          <Text>{t('placeHolders.instituteName')}</Text>
          <Input
            className="text-fields"
            placeholder={t('placeHolders.instituteName')}
            size="md"
            value={state.institute}
            onChange={(e) => setState({ institute: e.target.value })}
          />
          {state.instituteError !== '' ? (
            <Text className="input-error-message">
              {t(state.instituteError)}
            </Text>
          ) : (
            <></>
          )}
        </Box>
      </Flex>

      <Flex direction={['column', 'row']}>
        <Box flex={1}>
          <Text>{t('Institute Type')}</Text>
          <Select
            className="text-fields"
            size="md"
            value={state.instituteClassication}
            onChange={(e) =>
              setState({ instituteClassication: e.target.value })
            }
          >
            {Object.entries(EducationInstituteClassicationEnum).map(
              ([k, v]) => {
                return <option value={k}>{t(v)}</option>;
              }
            )}
          </Select>
          {state.instituteClassicationError !== '' ? (
            <Text className="input-error-message">
              {t(state.instituteClassicationError)}
            </Text>
          ) : (
            <></>
          )}
        </Box>
        <Box flex={1}></Box>
      </Flex>
      <Flex direction={['column', 'row']}>
        <Box flex={1}>
          <Text>{t('placeHolders.startDate')}</Text>
          <Input
            type="date"
            className="text-fields"
            placeholder={t('placeHolders.startDate')}
            size="md"
            value={state.startDate}
            onChange={(e) => setState({ startDate: e.target.value })}
          />
          {state.startDateError !== '' ? (
            <Text className="input-error-message">
              {t(state.startDateError)}
            </Text>
          ) : (
            <></>
          )}
        </Box>
        <Box flex={1} ml={[0, '10px']}>
          <Text>{t('placeHolders.endDate')}</Text>
          <Input
            type="date"
            className="text-fields"
            placeholder={t('placeHolders.endDate')}
            size="md"
            value={state.endDate}
            onChange={(e) => setState({ endDate: e.target.value })}
          />
        </Box>
      </Flex>
      <Flex>
        <Box flex={1} mr="10px">
          <Flex justify="center">
            <Button mt="20px" className="button-secondary" onClick={addOnClick}>
              {t('buttons.add')}
            </Button>
          </Flex>
        </Box>
      </Flex>

      <Flex mt={5} w={'100%'}>
        <Box flex={1} backgroundColor={'white'} overflowX={'auto'}>
          <TableContainer>
            <Table variant="simple">
              {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
              <Thead>
                <Tr>
                  <Th>{t('placeHolders.qualification')}</Th>
                  <Th>{t('placeHolders.instituteName')}</Th>
                  <Th>{t('Institute Type')}</Th>
                  <Th>{t('placeHolders.startDate')}</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              {educationalInfo.length > 0 ? (
                <Tbody>
                  {educationalInfo.map((item, index) => {
                    return (
                      <Tr key={index}>
                        <Td>{item.qualification}</Td>
                        <Td>{item.institute}</Td>
                        <Td>{item.instituteClassication}</Td>
                        <Td>{item.startDate}</Td>
                        <Td>
                          <DeleteIcon
                            w={5}
                            h={5}
                            color="red.500"
                            onClick={() => removeOnClick(item)}
                          />
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              ) : (
                <Tbody>
                  {' '}
                  <Tr>{t('errors.noRecords')}</Tr>{' '}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Box>
      </Flex>
      <Flex mb={5}>
        <Box flex={1} mr="10px">
          <Flex justify="flex-start">
            <Button mt="20px" className="button-primary" onClick={prevStep}>
              {t('buttons.previous')}
            </Button>
          </Flex>
        </Box>
        <Box alignItems="center" justifyContent="center" flex={1} ml="10px">
          <Flex justify="flex-end">
            <Button
              mt="20px"
              className="button-primary"
              onClick={submitFormData}
            >
              {t('buttons.next')}
            </Button>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default EducationalDataReg;
