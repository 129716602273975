import React from 'react'
import { Flex, Box, Text } from "@chakra-ui/react"
import { useTranslation } from 'react-i18next';

interface PersonalDataProps {
    userResponse: any
}

const PersonalTab = ({ userResponse }: PersonalDataProps) => {
    const { t } = useTranslation();

    return (
        <Box>
            <Flex>
                <Box flex={1}>
                    <Text color={'gray.500'}>{t('placeHolders.nationality')}</Text>
                    <Text mt={-5}>{userResponse.nationality}</Text>
                </Box>
                <Box flex={1}>
                    <Text color={'gray.500'}>{t('placeHolders.dob')}</Text>
                    <Text mt={-5}>{userResponse.dob}</Text>
                </Box>
            </Flex>
            <Flex>
                <Box flex={1}>
                    <Text color={'gray.500'}>{t('placeHolders.address')}</Text>
                    <Text mt={-5}>{userResponse.address}</Text>
                </Box>
                <Box flex={1}>
                    <Text color={'gray.500'}>{t('placeHolders.gender')}</Text>
                    <Text mt={-5}>{userResponse.sex}</Text>
                </Box>
            </Flex>
            <Flex>
                <Box flex={1}>
                    <Text color={'gray.500'}>{t('placeHolders.country')}</Text>
                    <Text mt={-5}>{userResponse.country}</Text>
                </Box>
                <Box flex={1}>
                    <Text color={'gray.500'}>{t('placeHolders.japaneseProficiency')}</Text>
                    <Text mt={-5}>{userResponse.japaneseProficiency}</Text>
                </Box>
            </Flex>
            <Flex>
                <Box flex={1}>
                    <Text color={'gray.500'}>{t('placeHolders.jlptComplatedOn')}</Text>
                    <Text mt={-5}>{userResponse.jlptComplatedOn ? userResponse.jlptComplatedOn.substring(0, 10) : ''}</Text>
                </Box>
                <Box flex={1}>
                    <Text color={'gray.500'}>{t('placeHolders.periodofStay')}</Text>
                    <Text mt={-5}>{userResponse.periodOfStay}</Text>
                </Box>
            </Flex>
            <Flex>
                <Box flex={1}>
                    <Text color={'gray.500'}>{t('placeHolders.statusOfResidence')}</Text>
                    <Text mt={-5}>{userResponse.statusOfResidence}</Text>
                </Box>
            </Flex>
        </Box>
    )
}

export default PersonalTab