import React, { useState } from 'react';
import { Box, Divider, Flex, Icon, IconButton, Image, Link, Menu, MenuButton, Text, VStack } from '@chakra-ui/react';
import { FaHome, FaFileAlt } from 'react-icons/fa';
import { HamburgerIcon } from '@chakra-ui/icons';
// @ts-ignore
import logoRed from "../../resources/svg/logoRed.svg"
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { pageHome, pageProfile } from '../../constants/StringConstants';

interface RootState {
    common: {
        isAuthenticated: boolean
    }
}

const SideMenu = () => {
    const isAuthenticated = useSelector((state: RootState) => state.common.isAuthenticated)
    const [navSize, changeNavSize] = useState("large")
    const { t } = useTranslation()

    const menuItemOnClick = () => {
        if (navSize == 'small') {
            changeNavSize('large')
        } else {
            changeNavSize('small')
        }
    }

    if (isAuthenticated) {
        return (
            <Box
                className='side-menu'
                w={navSize === "small" ? '75px' : '250px'}
                h="100vh"
                p={4}
            >
                <VStack spacing={4} align="stretch">
                    <IconButton
                        icon={<HamburgerIcon color='white' />}
                        aria-label="Toggle menu"
                        variant="ghost"
                        mr={2}
                        alignSelf='flex-start'
                        onClick={menuItemOnClick}
                    />
                    <Image
                        alignSelf='center'
                        borderRadius='full'
                        boxSize={75}
                        src={logoRed}
                    />
                    <Divider margin={0} />
                    <NavItem navSize={navSize} title={t('sideMenu.home')} icon={FaHome} active={false} href={pageHome}/>
                    <NavItem navSize={navSize} title={t('sideMenu.profile')} icon={FaFileAlt} active={false}  href={pageProfile}/>
                </VStack>
            </Box>
        );
    } else {
        <></>
    }
};

export default SideMenu;


interface NavItemProps {
    navSize: string,
    title: string,
    icon: any,
    active: any,
    href: string
}

const NavItem = ({ navSize, title, icon, active, href }: NavItemProps) => {
    return (
        <Flex
            flexDir='column'
            height='50px'
            w='100%'
            margin={0}
            borderRadius='sm'
            alignItems={navSize == 'small' ? 'center' : 'flex-start'}
            _hover={{ textDecor: 'none', backgroundColor: '#AECBCA' }}
        >
            <Menu placement='right'>
                <Link
                    backgroundColor={active && '#AECBCA'}
                    p={3}
                    borderRadius={8}
                    href={href}
                >
                    <MenuButton w='100%'>
                        <Flex>
                            <Icon as={icon} fontSize='xl' color={active ? '#82AAAD' : 'gray'} />
                            <Text ml={5} display={navSize === 'small' ? 'none' : 'flex'}>{title}</Text>
                        </Flex>
                    </MenuButton>
                </Link>
            </Menu>
        </Flex>
    )
}