import { useState } from 'react';

const useMergeState = (initialState: any) => {
  const [state, setState] = useState(initialState);

  const mergeState = (partialState: any) => {
    setState((prevState: any) => ({ ...prevState, ...partialState }));
  };

  return [state, mergeState];
};

export default useMergeState;