/* tslint:disable */
/* eslint-disable */
/**
 * Sora
 * Sora API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface EduInfoObject {
  /**
   *
   * @type {string}
   * @memberof Education
   */
  institute: string;
  /**
   *
   * @type {string}
   * @memberof Education
   */
  instituteClassication: EducationInstituteClassicationEnum;
  /**
   *
   * @type {string}
   * @memberof Education
   */
  qualification: string;
  /**
   *
   * @type {string}
   * @memberof Education
   */
  startDate: string;
  /**
   *
   * @type {string}
   * @memberof Education
   */
  endDate: string;
  /**
   *
   * @type {boolean}
   * @memberof Education
   */
  isReading: boolean;
}

export const EducationInstituteClassicationEnum = {
  University: 'UNIVERSITY',
  JuniorSchool: 'JUNIOR_SCHOOL',
  VocationalSchool: 'VOCATIONAL_SCHOOL',
  HigherVocationalSchool: 'HIGHER_VOCATIONAL_SCHOOL',
  JuniorHighSchool: 'JUNIOR_HIGH_SCHOOL',
  HighSchool: 'HIGH_SCHOOL',
  JuniorCollege: 'JUNIOR_COLLEGE',
  CollegeOfTechnology: 'COLLEGE_OF_TECHNOLOGY',
  Other: 'OTHER',
} as const;

export type EducationInstituteClassicationEnum =
  (typeof EducationInstituteClassicationEnum)[keyof typeof EducationInstituteClassicationEnum];
