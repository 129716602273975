import axios from 'axios';
import { createLog } from './Util';
import { getAccessToken } from '../storage/CookieStorage';

export const uploadImage = (file: File, url: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      await axios({
        method: 'post',
        url: url,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      createLog(error);
    }
  });
};

export const apiRequest = (
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  url: string,
  body?: object
) => {
  return new Promise(async (resolve, reject) => {
    const api = axios.create();
    // Request interceptor
    api.interceptors.request.use(
      (config) => {
        // Modify request config here (e.g., add headers, authentication tokens)
        return config;
      },
      (error) => {
        console.log('request interceptor');
        // Handle request error
        return Promise.reject(error);
      }
    );

    // Response interceptor
    api.interceptors.response.use(
      (response) => {
        // Modify response data here (e.g., transform, filter)
        console.log('response interceptor');
        console.log(response);
        return response;
      },
      (error) => {
        // Handle response error
        console.log('response interceptor');
        console.log(error);
        return Promise.reject(error);
      }
    );

    const headers = getHeaders();

    const requestOptions = {
      method: method,
      url: url,
      headers: headers,
      data: body,
    };
    await axios(requestOptions)
      .then((response) => {
        // Handle successful response
        resolve(response.data);
      })
      .catch((error) => {
        // Handle error(error);
        if (error?.response?.status === 401) {
          window.location.href = '/login';
        }
        reject(error);
      });
  });
};

const getHeaders = () => {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAccessToken()}`,
  };
};
