import React from 'react';
import { useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Input,
  Text,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import useMergeState from '../../hooks/useMergeState';
import { valueIsEmpty } from '../../../util/Util';
import { DeleteIcon } from '@chakra-ui/icons';

interface certificationDataProps {
  nextStep: () => void;
  prevStep: () => void;
  setCertificationData: (certificationData: CertificationDataObject[]) => void;
  certificationData: CertificationDataObject[];
}

const CertificationDataReg = ({
  nextStep,
  prevStep,
  setCertificationData,
  certificationData,
}: certificationDataProps) => {
  const { t } = useTranslation();
  const [state, setState] = useMergeState({
    title: '',
    issuedBy: '',
    startDate: '',
    expirationDate: '',
    credentialUrl: '',
    credentialId: '',
    titleError: '',
    issuedByError: '',
    startDateError: '',
  });
  const [certificationInfo, setCertificationInfo] = useState(certificationData);

  // after form submit validating the form data using validator
  const submitFormData = (e: any) => {
    e.preventDefault();
    if (certificationInfo.length > 0) {
      setCertificationData(certificationInfo);
    }
    nextStep();
  };

  const validateInputs = () => {
    let isValid = true;
    if (valueIsEmpty(state.title)) {
      setState({ titleError: 'required' });
      isValid = false;
    } else {
      setState({ titleError: '' });
    }
    if (valueIsEmpty(state.issuedBy)) {
      setState({ issuedByError: 'required' });
    } else {
      setState({ issuedByError: '' });
    }
    if (valueIsEmpty(state.startDate)) {
      setState({ startDateError: 'required' });
      isValid = false;
    } else {
      setState({ startDateError: '' });
    }
    return isValid;
  };

  const addOnClick = () => {
    if (validateInputs()) {
      const certInfo = {
        title: state.title,
        issuedBy: state.issuedBy,
        startDate: state.startDate,
        expirationDate: state.expirationDate,
        credentialUrl: state.credentialUrl,
        credentialId: state.credentialId,
      };
      certificationInfo.push(certInfo);
      setCertificationInfo(certificationInfo);
      setState({
        title: '',
        issuedBy: '',
        startDate: '',
        expirationDate: '',
        credentialUrl: '',
        credentialId: '',
      });
    }
  };

  const removeOnClick = (item: CertificationDataObject) => {
    const arr = certificationInfo.filter((x) => x !== item);
    setCertificationInfo(arr);
  };

  return (
    <>
      <Heading>{t('titles.certificationInformation')}</Heading>
      <Flex direction={['column', 'row']}>
        <Box flex={1}>
          <Text>{t('placeHolders.name')}</Text>
          <Input
            className="text-fields"
            placeholder={t('placeHolders.name')}
            size="md"
            value={state.title}
            onChange={(e) => setState({ title: e.target.value })}
          />
          {state.titleError !== '' ? (
            <Text className="input-error-message">{t(state.titleError)}</Text>
          ) : (
            <></>
          )}
        </Box>
        <Box flex={1} ml={[0, '10px']}>
          <Text>{t('placeHolders.issuingOrganization')}</Text>
          <Input
            className="text-fields"
            placeholder={t('placeHolders.issuingOrganization')}
            size="md"
            value={state.issuedBy}
            onChange={(e) => setState({ issuedBy: e.target.value })}
          />
          {state.issuedByError !== '' ? (
            <Text className="input-error-message">
              {t(state.issuedByError)}
            </Text>
          ) : (
            <></>
          )}
        </Box>
      </Flex>

      <Flex direction={['column', 'row']}>
        <Box flex={1}>
          <Text>{t('placeHolders.issueDate')}</Text>
          <Input
            type="date"
            className="text-fields"
            placeholder={t('placeHolders.issueDate')}
            size="md"
            value={state.startDate}
            onChange={(e) => setState({ startDate: e.target.value })}
          />
          {state.startDateError !== '' ? (
            <Text className="input-error-message">
              {t(state.startDateError)}
            </Text>
          ) : (
            <></>
          )}
        </Box>
        <Box flex={1} ml={[0, '10px']}>
          <Text>{t('placeHolders.expiryDate')}</Text>
          <Input
            type="date"
            className="text-fields"
            placeholder={t('placeHolders.expiryDate')}
            size="md"
            value={state.expirationDate}
            onChange={(e) => setState({ expirationDate: e.target.value })}
          />
        </Box>
      </Flex>

      <Flex direction={['column', 'row']}>
        <Box flex={1}>
          <Text>{t('placeHolders.credentialId')}</Text>
          <Input
            className="text-fields"
            placeholder={t('placeHolders.credentialId')}
            size="md"
            value={state.credentialId}
            onChange={(e) => setState({ credentialId: e.target.value })}
          />
        </Box>
        <Box flex={1} ml={[0, '10px']}>
          <Text>{t('placeHolders.credentialUrl')}</Text>
          <Input
            className="text-fields"
            placeholder={t('placeHolders.credentialUrl')}
            size="md"
            value={state.credentialUrl}
            onChange={(e) => setState({ credentialUrl: e.target.value })}
          />
        </Box>
      </Flex>
      <Flex>
        <Box flex={1} ml={[0, '10px']}>
          <Flex justify="center">
            <Button mt="20px" className="button-secondary" onClick={addOnClick}>
              {t('buttons.add')}
            </Button>
          </Flex>
        </Box>
      </Flex>

      <Flex mt={5} w={'100%'}>
        <Box flex={1} backgroundColor={'white'} overflowX={'auto'}>
          <TableContainer>
            <Table variant="simple">
              {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
              <Thead>
                <Tr>
                  <Th>{t('placeHolders.name')}</Th>
                  <Th>{t('placeHolders.issuingOrganization')}</Th>
                  <Th>{t('placeHolders.startDate')}</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              {certificationInfo.length > 0 ? (
                <Tbody>
                  {certificationInfo.map((item, index) => {
                    return (
                      <Tr key={index}>
                        <Td>{item.title}</Td>
                        <Td>{item.issuedBy}</Td>
                        <Td>{item.startDate}</Td>
                        <Td>
                          <DeleteIcon
                            w={5}
                            h={5}
                            color="red.500"
                            onClick={() => removeOnClick(item)}
                          />
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              ) : (
                <Tbody>
                  {' '}
                  <Tr>{t('errors.noRecords')}</Tr>{' '}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Box>
      </Flex>
      <Flex mb={5}>
        <Box flex={1} mr="10px">
          <Flex justify="flex-start">
            <Button mt="20px" className="button-primary" onClick={prevStep}>
              {t('buttons.previous')}
            </Button>
          </Flex>
        </Box>
        <Box alignItems="center" justifyContent="center" flex={1} ml="10px">
          <Flex justify="flex-end">
            <Button
              mt="20px"
              className="button-primary"
              onClick={submitFormData}
            >
              {t('buttons.next')}
            </Button>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default CertificationDataReg;
