import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Heading,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  JOB_SEARCH_ITEM_PER_PAGE,
  pageCandidateRegistration,
  pageLogin,
  pageLogout,
  pageProfile,
} from '../../constants/StringConstants';
import {
  JobSearch,
  getJobOpenings,
  searchJobOpenings,
} from '../../service/JobService';
import '../../styles/App.scss';
import { JobOpening } from '../types/job-opening';
import { cities } from './../../resources/data/jp-cities';
import logoWithTextRed from './../../resources/svg/logoWithTextRed.svg';
import nodata from './../../resources/svg/no-results.svg';
import { JobDescription } from './Jobs/JobDescription';
import {
  PaginationContainer,
  PaginationPrevious,
  PaginationPageGroup,
  PaginationPage,
  PaginationNext,
  usePagination,
  Pagination,
} from '@ajna/pagination';
import { JobSearchResults } from '../types/job-search-results';
import { error } from 'console';

export const SearchPage = () => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.common.isAuthenticated
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [jobs, setJobs] = useState<JobOpening[]>([]);
  const [job, setJob] = useState<JobOpening>();
  const [total, setTotal] = useState(0);
  const { currentPage, setCurrentPage, pagesCount, pages, setPageSize } =
    usePagination({
      total,
      initialState: { currentPage: 1, pageSize: 3 },
    });

  const { register, handleSubmit, getValues } = useForm<JobSearch>();

  const [isLarger] = useMediaQuery('(min-width: 800px)');
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    getJobOpenings().then((response) => {
      setJobs(response?.result);
      setJob(!!response?.result.length ? response?.result[0] : undefined);
    });
  }, []);

  useEffect(() => {
    const skip = (currentPage - 1) * JOB_SEARCH_ITEM_PER_PAGE;
    searchJobOpenings(getValues(), skip)
      .then((response) => {
        updateView(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage]);

  const onSeach = async (formData: JobSearch) => {
    const response = await searchJobOpenings(formData, 0);
    updateView(response);
  };

  const updateView = (response: JobSearchResults) => {
    setJobs(response?.result);
    setJob(!!response?.result.length ? response?.result[0] : undefined);
    setTotal(response.count);
    setPageSize(JOB_SEARCH_ITEM_PER_PAGE);
  };

  return (
    <Box w={'100%'} className="search-page">
      <Flex p={'15px'} w={'100%'} justifyContent="space-between">
        <img src={logoWithTextRed} width={'150px'} alt="logo" />
        <Flex>
          {!isAuthenticated && (
            <>
              <Button onClick={() => navigate(pageCandidateRegistration)}>
                {t('Register')}
              </Button>
              <Button variant="ghost" onClick={() => navigate(pageLogin)}>
                {t('Login')}
              </Button>
            </>
          )}
          {isAuthenticated && (
            <>
              <Button variant="ghost" onClick={() => navigate(pageProfile)}>
                {t('Profile')}
              </Button>
              <Button onClick={() => navigate(pageLogout)}>
                {t('Logout')}
              </Button>
            </>
          )}
        </Flex>
      </Flex>
      <Flex
        className="header"
        minHeight={'200px'}
        justifyContent={'Center'}
        alignItems={'center'}
      >
        <Flex w={['100%', '50%']} backgroundColor={'#fff'} p={[2, 0]}>
          <form onSubmit={handleSubmit(onSeach)} style={{ width: '100%' }}>
            <Flex direction={['column', 'row']} {...register('city')}>
              <Select placeholder="Select City" mb={[2, 0]}>
                {cities.map((city) => {
                  return <option>{city.city}</option>;
                })}
              </Select>
              <Input
                placeholder={'Search jobs'}
                {...register('search')}
                mb={[2, 0]}
              />
              <Button
                type="submit"
                w={['100%', '75px']}
                borderRadius={0}
                minW={'100px'}
              >
                <Icon as={SearchIcon} mr={'5px'} />
                {t('Search')}
              </Button>
            </Flex>
          </form>
        </Flex>
      </Flex>

      <Flex direction={'row'} justifyContent={'Center'} width={[]} mt={'15px'}>
        <Stack>
          {jobs &&
            jobs.map((job) => {
              return (
                <Card
                  maxW="md"
                  mb={[2, 2]}
                  minW={['sm', 'md']}
                  onClick={() => {
                    setJob(job);
                    if (!isLarger) {
                      onOpen();
                    }
                  }}
                >
                  <CardHeader>
                    <Heading size="md">{job.title}</Heading>
                  </CardHeader>
                  <CardBody>
                    <Text>
                      <header>{job.description}</header>
                    </Text>
                  </CardBody>
                </Card>
              );
            })}
        </Stack>
        {isLarger && (
          <Box maxW="2xl" minW="2xl" ml={'15px'}>
            {job && <JobDescription job={job} />}
          </Box>
        )}
      </Flex>

      {total > 0 && (
        <Flex
          justifyContent={'center'}
          alignItems={'center'}
          direction={'column'}
          w={'100%'}
        >
          <Divider w={'50%'} />
          <Pagination
            pagesCount={pagesCount}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          >
            <PaginationContainer>
              <PaginationPrevious>Previous</PaginationPrevious>
              <PaginationPageGroup>
                {pages.map((page: number) => (
                  <PaginationPage
                    w={10}
                    key={`pagination_page_${page}`}
                    page={page}
                  />
                ))}
              </PaginationPageGroup>
              <PaginationNext ml={8}>Next</PaginationNext>
            </PaginationContainer>
          </Pagination>
        </Flex>
      )}
      {(!jobs || jobs?.length === 0) && (
        <Flex
          mt={16}
          mb={16}
          direction={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <img src={nodata} width={'150px'} alt="logo" />
          <Text>{t('No results found')}</Text>
        </Flex>
      )}
      <Divider />
      <Flex justifyContent={'center'}>
        <Text textAlign={'center'}>
          <a
            href="http://yoyogroup.jp"
            target="_blank"
            rel="noopener noreferrer"
          >
            Copyright © YOYO Co., Ltd.
          </a>
        </Text>
      </Flex>

      <>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            {/* <ModalHeader>Modal Title</ModalHeader> */}
            <ModalCloseButton />
            <ModalBody>{job && <JobDescription job={job} />}</ModalBody>
          </ModalContent>
        </Modal>
      </>
    </Box>
  );
};
