import { URL_ASSIGNMENTS } from '../constants/UrlConstants';
import { apiRequest } from '../util/ServiceUtils';
import { createLog } from '../util/Util';

export const applyJobById = (jobId: string) => {
  return new Promise(async (resolve, reject) => {
    await apiRequest('POST', `${URL_ASSIGNMENTS}/candidate/${jobId}/apply`, {})
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        createLog(err);
        reject(err);
      });
  });
};
