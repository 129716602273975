import React from 'react';
import { useSelector } from 'react-redux'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Center,
    VStack,
  } from '@chakra-ui/react'
import { hideAlertModal } from '../redux';
import { useTranslation } from 'react-i18next';

  interface RootState {
    common: {
        alertModel: {
            visible: boolean, 
            type:  'success' | 'error' | 'warning' | 'info', 
            message: string
        }
    }
}

const AlertModal = () => {
    const { t } = useTranslation();
    const alertModel = useSelector((state: RootState) => state.common.alertModel)
    // const { isOpen, onOpen, onClose } = useDisclosure()

    const getTitle = () => {
        switch (alertModel.type) {
            case 'success':
                return t('titles.success')

            case 'error':
                return t('titles.error')

            case 'warning':
                return t('titles.warning')
        
            default:
                return t('titles.info')
        }
    }

    const getColor = () => {
      switch (alertModel.type) {
          case 'success':
              return 'green'

          case 'error':
              return 'red'

          case 'warning':
              return 'yellow'
      
          default:
              return 'blue'
      }
  }

    return (
      <>
        <Modal isOpen={alertModel.visible} onClose={hideAlertModal}>
          <ModalOverlay />
          <ModalContent>
            <Center>
                <VStack>
                <ModalCloseButton />
                <ModalHeader>{getTitle()}</ModalHeader>
                <ModalBody>{t(alertModel.message)}</ModalBody>
                </VStack>
            </Center>
            <ModalFooter mt={5} justifyContent='center' color='white' backgroundColor={getColor()} height='50px' onClick={hideAlertModal}>
                OK
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
}

export default AlertModal