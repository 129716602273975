import React from 'react'
import {
    Box,
    Flex,
    Heading,
    Input,
    Text,
    Button,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface ProfessionalDataProps {
    userResponse: any
}

const ProfessionalTab = ({ userResponse }: ProfessionalDataProps) => {
    const { t } = useTranslation();

    return (
        <Box flex={1} backgroundColor={'white'}>
            {userResponse.work !== undefined && userResponse.work.length > 0 ? (
                <TableContainer>
                    <Table variant='striped' colorScheme='gray'>
                        {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
                        <Thead>
                            <Tr>
                                <Th>{t('placeHolders.company')}</Th>
                                <Th>{t('placeHolders.positionHeld')}</Th>
                                <Th>{t('placeHolders.startDate')}</Th>
                                <Th>{t('placeHolders.endDate')}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {userResponse.work.map((item: any, index: any) => {
                                return (
                                    <Tr key={index}>
                                        <Td>{item.company}</Td>
                                        <Td>{item.title}</Td>
                                        <Td>{item.startDate.substring(0, 10)}</Td>
                                        <Td>{item.endDate ? item.endDate.substring(0, 10) : ''}</Td>
                                    </Tr>
                                );
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            ) : (
                <Text>{t('errors.noRecords')}</Text>
            )}
        </Box>
    )
}

export default ProfessionalTab