import React from 'react'
import {
  Box,
  Flex,
  Heading,
  Input,
  Text,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface CertificationDataProps {
  userResponse: any
}

const CertificationTab = ({ userResponse }: CertificationDataProps) => {
  const { t } = useTranslation();

  return (
    <Box flex={1} backgroundColor={'white'}>
      {userResponse.certifications != undefined && userResponse.certifications.length > 0 ? (
        <TableContainer>
          <Table variant='striped' colorScheme='gray'>
            {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
            <Thead>
              <Tr>
                <Th>{t('placeHolders.name')}</Th>
                <Th>{t('placeHolders.issuingOrganization')}</Th>
                <Th>{t('placeHolders.startDate')}</Th>
                <Th>{t('placeHolders.expiryDate')}</Th>
                <Th>{t('placeHolders.credentialId')}</Th>
                <Th>{t('placeHolders.credentialUrl')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {userResponse.certifications.map((item: any, index: any) => {
                return (
                  <Tr key={index}>
                    <Td>{item.title}</Td>
                    <Td>{item.issuedBy}</Td>
                    <Td>{item.startDate.substring(0, 10)}</Td>
                    <Td>{item.expirationDate ? item.expirationDate.substring(0, 10) : ''}</Td>
                    <Td>{item.credentialId}</Td>
                    <Td>{item.credentialUrl}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Text>{t('errors.noRecords')}</Text>
      )}
    </Box>
  )
}

export default CertificationTab