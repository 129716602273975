import React from 'react';
import { useSelector } from 'react-redux'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    useDisclosure,
    Spinner,
    Flex,
    Box,
    AbsoluteCenter,
} from '@chakra-ui/react'

interface RootState {
    common: {
        loadingIndicatorVisible: boolean
    }
}

const LoadingIndicator = () => {
    const showLoading = useSelector((state: RootState) => state.common.loadingIndicatorVisible)
    const { onClose } = useDisclosure()
    return (
        <Modal isOpen={showLoading} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent height={100} width={100}>
                <ModalBody>
                    <Flex>
                        <Box justifyItems='center' alignItems='center'>
                            <AbsoluteCenter>
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                size='xl'
                            />
                            </AbsoluteCenter>
                        </Box>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default LoadingIndicator