import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
// @ts-ignore
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { pageLogin } from '../../constants/StringConstants';
import Navbar from '../common/NavBar';
import SideMenu from '../common/SideMenu';

const LandingPage = () => {
  const navigate = useNavigate();

  const isAuthenticated = useSelector(
    (state: RootState) => state.common.isAuthenticated
  );

  if (!isAuthenticated) {
    navigate(pageLogin);
  }

  return (
    <Flex>
      <SideMenu />
      <Flex flexDirection="column" w="100%">
        <Box>
          <Navbar />
        </Box>
        <Flex>
          <Outlet />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LandingPage;

// import React from 'react';
// // import { logoWithTextRed } from "../../resources/svg"
// import NxtButtons from "../controls/Button"
// import NxtInputs from "../controls/Input"
// import { useNavigate } from 'react-router-dom';
// import LoginPage from "./LoginPage";
// import { pageCandidateRegistration, pageLogin } from "../../constants/StringConstants";
// import { useTranslation } from "react-i18next";
// // @ts-ignore
// import logoWithTextRed from "../../resources/svg/logoWithTextRed.svg"

// const LandingPage = () => {
//     const { t } = useTranslation()
//     const navigate = useNavigate()

//     const handleSearch = () => {
//         navigate(pageCandidateRegistration);
//         console.log('Search')
//     }

//     const onChangeSearchText = (event: any) => {
//         console.log(event.target.value)
//     }

//     const onRegisterClick = () => {

//     }

//     const onLoginClick = () => {
//         navigate(pageLogin);
//     }

//     return (
//         <div>
//             <div className="row justify-content-end">
//                 <div className="col-sm-1 mt-2"><button className="btn" onClick={onRegisterClick}>{t('buttons.register')}</button></div>
//                 <div className="col-sm-1 mt-2"><button className="btn" onClick={onLoginClick}>{t('buttons.login')}</button></div>
//             </div>
//             <div className="row justify-content-center mt-5">
//                 <div className="card col-md-8 border-0 bg-transparent mt-5">
//                     <div className="row justify-content-center mt-5">
//                         <div className="col-lg-8">
//                             <img className="img-fluid w-100" src={logoWithTextRed} alt="React Logo" />
//                         </div>
//                     </div>
//                     <div className="row justify-content-center mt-1">
//                         <div className="col-lg-6 mt-2">
//                             <NxtInputs placeholder="" onChange={onChangeSearchText} />
//                         </div>
//                         <div className="col-lg-2 mt-2">
//                             <NxtButtons className={"col-lg-12 button-primary"} title={t('buttons.search')} onClick={handleSearch} />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default LandingPage
