export interface City {
  city: string;
  lat: string;
  lng: string;
  country: string;
  iso2: string;
  admin_name: string;
  capital: string;
  population: string;
  population_proper: string;
}

export const cities: City[] = [
  {
    city: 'Tokyo',
    lat: '35.6897',
    lng: '139.6922',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: 'primary',
    population: '37732000',
    population_proper: '13515271',
  },
  {
    city: 'Ōsaka',
    lat: '34.6939',
    lng: '135.5022',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Ōsaka',
    capital: 'admin',
    population: '15126000',
    population_proper: '2725006',
  },
  {
    city: 'Nagoya',
    lat: '35.1833',
    lng: '136.9000',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Aichi',
    capital: 'admin',
    population: '9197000',
    population_proper: '2320361',
  },
  {
    city: 'Yokohama',
    lat: '35.4442',
    lng: '139.6381',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Kanagawa',
    capital: 'admin',
    population: '3757630',
    population_proper: '3757630',
  },
  {
    city: 'Fukuoka',
    lat: '33.5833',
    lng: '130.4000',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Fukuoka',
    capital: 'admin',
    population: '2286000',
    population_proper: '1588924',
  },
  {
    city: 'Sapporo',
    lat: '43.0667',
    lng: '141.3500',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Hokkaidō',
    capital: 'admin',
    population: '1961690',
    population_proper: '1959313',
  },
  {
    city: 'Kawasaki',
    lat: '35.5167',
    lng: '139.7000',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Kanagawa',
    capital: '',
    population: '1539522',
    population_proper: '1539522',
  },
  {
    city: 'Kōbe',
    lat: '34.6900',
    lng: '135.1956',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Hyōgo',
    capital: 'admin',
    population: '1521707',
    population_proper: '1521707',
  },
  {
    city: 'Kyōto',
    lat: '35.0117',
    lng: '135.7683',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Kyōto',
    capital: 'admin',
    population: '1464890',
    population_proper: '1463723',
  },
  {
    city: 'Saitama',
    lat: '35.8614',
    lng: '139.6456',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Saitama',
    capital: 'admin',
    population: '1325843',
    population_proper: '1325843',
  },
  {
    city: 'Hiroshima',
    lat: '34.3914',
    lng: '132.4519',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Hiroshima',
    capital: 'admin',
    population: '1198021',
    population_proper: '1198021',
  },
  {
    city: 'Sendai',
    lat: '38.2682',
    lng: '140.8694',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Miyagi',
    capital: 'admin',
    population: '1061177',
    population_proper: '1061177',
  },
  {
    city: 'Setagaya',
    lat: '35.6466',
    lng: '139.6532',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '940509',
    population_proper: '940509',
  },
  {
    city: 'Nerima',
    lat: '35.7355',
    lng: '139.6517',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '742463',
    population_proper: '742463',
  },
  {
    city: 'Ōta-ku',
    lat: '35.5613',
    lng: '139.7160',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '737187',
    population_proper: '737187',
  },
  {
    city: 'Edogawa',
    lat: '35.7066',
    lng: '139.8683',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Chiba',
    capital: '',
    population: '697932',
    population_proper: '697932',
  },
  {
    city: 'Adachi',
    lat: '35.7749',
    lng: '139.8044',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '682326',
    population_proper: '682326',
  },
  {
    city: 'Suginami-ku',
    lat: '35.6995',
    lng: '139.6364',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '583609',
    population_proper: '583609',
  },
  {
    city: 'Itabashi',
    lat: '35.7512',
    lng: '139.7093',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '581337',
    population_proper: '581337',
  },
  {
    city: 'Kōtō-ku',
    lat: '35.6728',
    lng: '139.8173',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '523117',
    population_proper: '523117',
  },
  {
    city: 'Katsushika-ku',
    lat: '35.7434',
    lng: '139.8472',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '453410',
    population_proper: '453410',
  },
  {
    city: 'Shinagawa-ku',
    lat: '35.6092',
    lng: '139.7302',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '415729',
    population_proper: '415729',
  },
  {
    city: 'Toyonaka',
    lat: '34.7833',
    lng: '135.4667',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Ōsaka',
    capital: '',
    population: '401233',
    population_proper: '401233',
  },
  {
    city: 'Kita-ku',
    lat: '35.7528',
    lng: '139.7336',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '353058',
    population_proper: '353058',
  },
  {
    city: 'Shinjuku',
    lat: '35.6939',
    lng: '139.7034',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '349385',
    population_proper: '349385',
  },
  {
    city: 'Nakano',
    lat: '35.7073',
    lng: '139.6637',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '341014',
    population_proper: '341014',
  },
  {
    city: 'Toshima',
    lat: '35.7261',
    lng: '139.7167',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '297954',
    population_proper: '297954',
  },
  {
    city: 'Meguro',
    lat: '35.6415',
    lng: '139.6983',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '286905',
    population_proper: '286905',
  },
  {
    city: 'Sumida',
    lat: '35.7107',
    lng: '139.8015',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '270823',
    population_proper: '270823',
  },
  {
    city: 'Minato',
    lat: '35.6580',
    lng: '139.7515',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '260486',
    population_proper: '260486',
  },
  {
    city: 'Chōfugaoka',
    lat: '35.6506',
    lng: '139.5407',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '240359',
    population_proper: '240359',
  },
  {
    city: 'Bunkyō-ku',
    lat: '35.7080',
    lng: '139.7523',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '240069',
    population_proper: '240069',
  },
  {
    city: 'Shibuya-ku',
    lat: '35.6637',
    lng: '139.6977',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '235193',
    population_proper: '235193',
  },
  {
    city: 'Arakawa',
    lat: '35.7361',
    lng: '139.7834',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '217891',
    population_proper: '217891',
  },
  {
    city: 'Taitō',
    lat: '35.7126',
    lng: '139.7800',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '210528',
    population_proper: '210528',
  },
  {
    city: 'Nishitōkyō',
    lat: '35.7256',
    lng: '139.5383',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '207436',
    population_proper: '207436',
  },
  {
    city: 'Kamirenjaku',
    lat: '35.6836',
    lng: '139.5595',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '194460',
    population_proper: '194460',
  },
  {
    city: 'Chūō-ku',
    lat: '35.6706',
    lng: '139.7720',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '169995',
    population_proper: '169179',
  },
  {
    city: 'Musashino',
    lat: '35.7177',
    lng: '139.5661',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '148971',
    population_proper: '148971',
  },
  {
    city: 'Moriguchi',
    lat: '34.7375',
    lng: '135.5642',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Ōsaka',
    capital: '',
    population: '141849',
    population_proper: '141849',
  },
  {
    city: 'Kokubunji',
    lat: '35.7109',
    lng: '139.4622',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '129619',
    population_proper: '129619',
  },
  {
    city: 'Koganei',
    lat: '35.6995',
    lng: '139.5030',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '127226',
    population_proper: '127226',
  },
  {
    city: 'Shibuya',
    lat: '35.6536',
    lng: '139.7092',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '102056',
    population_proper: '102056',
  },
  {
    city: 'Komae',
    lat: '35.6348',
    lng: '139.5787',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Tōkyō',
    capital: '',
    population: '83677',
    population_proper: '83677',
  },
  {
    city: 'Warabi',
    lat: '35.8256',
    lng: '139.6797',
    country: 'Japan',
    iso2: 'JP',
    admin_name: 'Saitama',
    capital: '',
    population: '74996',
    population_proper: '74996',
  },
];
