import React from 'react'
import { Flex, Box, Text } from "@chakra-ui/react"
import { useTranslation } from 'react-i18next';

interface OtherDataProps {
    userResponse: any
}

const OtherTab = ({ userResponse }: OtherDataProps) => {
    const { t } = useTranslation();

    return (
        <Box>
            <Flex>
                <Box flex={1}>
                    <Text color={'gray.500'}>{t('placeHolders.yourExperience')}</Text>
                    <Text mt={-5}>{userResponse.youBest}</Text>
                </Box>
                <Box flex={1}>
                    <Text color={'gray.500'}>{t('placeHolders.reasonWorkInJapan')}</Text>
                    <Text mt={-5}>{userResponse.whyJapan}</Text>
                </Box>
            </Flex>
            <Flex>
                <Box flex={1}>
                    <Text color={'gray.500'}>{t('placeHolders.strengthWeak')}</Text>
                    <Text mt={-5}>{userResponse.sw}</Text>
                </Box>
                <Box flex={1}>
                    <Text color={'gray.500'}>{t('placeHolders.hobbyFav')}</Text>
                    <Text mt={-5}>{userResponse.hobbies}</Text>
                </Box>
            </Flex>
            <Flex>
                <Box flex={1}>
                    <Text color={'gray.500'}>{t('placeHolders.goodPoints')}</Text>
                    <Text mt={-5}>{userResponse.goodPoints}</Text>
                </Box>
                <Box flex={1}>
                </Box>
            </Flex>
        </Box>
    )
}

export default OtherTab