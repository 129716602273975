import { JobSearchResults } from '../components/types/job-search-results';
import { JOB_SEARCH_ITEM_PER_PAGE } from '../constants/StringConstants';
import { URL_JOB_OPENINGS } from '../constants/UrlConstants';
import { apiRequest } from '../util/ServiceUtils';
import { createLog } from '../util/Util';

export const getJobOpenings = (): Promise<JobSearchResults> => {
  return new Promise(async (resolve, reject) => {
    await apiRequest('GET', URL_JOB_OPENINGS)
      .then((res) => {
        resolve(res as JobSearchResults);
      })
      .catch((err) => {
        createLog(err);
        reject(err);
      });
  });
};

export type JobSearch = {
  search: string;
  city: string;
};

export const searchJobOpenings = (
  searchData: JobSearch,
  skip: number
): Promise<JobSearchResults> => {
  return new Promise(async (resolve, reject) => {
    await apiRequest(
      'GET',
      `${URL_JOB_OPENINGS}?search=${searchData.search}&city=${searchData.city}&skip=${skip}&take=${JOB_SEARCH_ITEM_PER_PAGE}`
    )
      .then((res) => {
        resolve(res as JobSearchResults);
      })
      .catch((err) => {
        createLog(err);
        reject(err);
      });
  });
};
