import React, { useState, useEffect } from 'react';
import {
  Box,
  ChakraProvider,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react';
import './styles/App.css';
import './styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginPage from './components/pages/LoginPage';
import {
  pageCandidateRegistration,
  pageHome,
  pageLogin,
  pageLogout,
  pageProfile,
} from './constants/StringConstants';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import CandidateRegistrationPage from './components/pages/candidateRegistration/CandidateRegistrationPage';
import LandingPage from './components/pages/LandingPage';
import { Provider, useSelector } from 'react-redux';
import Store from './components/redux/Store';
import LoadingIndicator from './components/common/LoadingIndicator';
import AlertModal from './components/common/AlertModal';
import ProfilePage from './components/pages/profile/ProfilePage';
import SideMenu from './components/common/SideMenu';
import Navbar from './components/common/NavBar';
import { getAuthenticated } from './storage/CookieStorage';
import { isAuthenticated } from './components/redux';
import LogoutPage from './components/pages/LogoutPage';
import HomePage from './components/pages/HomePage';
import { SearchPage } from './components/pages/SearchPage';
import { getRouts, routeItems } from './constants/routes';
import LanguageDetector from 'i18next-browser-languagedetector';
// import Header from './components/common/Header';

// Load translations
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: require('./localization/en.json'),
      },
      si: {
        translation: require('./localization/si.json'),
      },
      ja: {
        translation: require('./localization/jp.json'),
      },
      jp: {
        translation: require('./localization/jp.json'),
      },
    },
    lng: 'ja',
    fallbackLng: 'en', // Fallback language if translation is missing
    interpolation: {
      escapeValue: false, // Disable HTML escaping
    },
  });

function App() {
  useEffect(() => {
    const authentic = getAuthenticated();
    isAuthenticated(authentic === 'true');
  }, []);

  // Store.subscribe(() => console.log('Redux store - ', Store.getState()))
  const navigationRoutes = getRouts(routeItems);
  return (
    <Provider store={Store}>
      <ChakraProvider>
        <LoadingIndicator />
        <AlertModal />
        <Flex>
          {/* <SideMenu /> */}
          <Flex flexDirection="column" w="100%">
            {/* <Navbar /> */}
            <BrowserRouter>
              <Routes>{navigationRoutes}</Routes>
            </BrowserRouter>
          </Flex>
        </Flex>
      </ChakraProvider>
    </Provider>
  );
}

export default App;
