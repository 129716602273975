import React, { useRef, useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Textarea,
  Button,
  Image,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import useMergeState from '../../hooks/useMergeState';

interface personalDataProps {
  prevStep: () => void;
  setOtherData: (otherData: OtherRegDataObject) => void;
  otherData: OtherRegDataObject;
}

const OtherDataReg = ({
  prevStep,
  setOtherData,
  otherData,
}: personalDataProps) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  // const [selectedImage, setSelectedImage] = useState('https://bit.ly/dan-abramov')

  const [state, setState] = useMergeState({
    file: null,
    imageUrl:
      otherData.selectedImage !== undefined
        ? otherData.selectedImage
        : 'https://sora.yoyointernational.jp/avatar.png',
    whyJapan: otherData.whyJapan,
    youBest: otherData.youBest,
    sw: otherData.sw,
    hobbies: otherData.hobbies,
    goodPoints: otherData.goodPoints,
  });

  const handleImageOnClick = () => {
    if (fileInputRef.current !== null) {
      fileInputRef.current.click();
    }
  };

  const handleFileSelect = (event: any) => {
    const selectedFile = event.target.files[0];
    const imageUrl = URL.createObjectURL(selectedFile);
    setState({ file: selectedFile, imageUrl });
  };

  // after form submit validating the form data using validator
  const submitFormData = async (e: any) => {
    e.preventDefault();

    setOtherData({
      selectedImage: state.imageUrl,
      imageFile: state.file,
      whyJapan: state.whyJapan,
      youBest: state.youBest,
      sw: state.sw,
      hobbies: state.hobbies,
      goodPoints: state.goodPoints,
    });
  };

  return (
    <>
      <Heading>{t('titles.otherInformation')}</Heading>

      <Flex
        mt={5}
        justifyContent="center"
        alignItems={'center'}
        direction={'column'}
      >
        <Flex direction={'column'} alignItems={'center'} mb={5}>
          <Text>{t('click on the image to add prpfile picture')}</Text>
          <Image
            w={'200px'}
            src={state.imageUrl}
            objectFit="contain"
            onClick={handleImageOnClick}
            alt="Dan Abramov"
          />
          <input
            type="file"
            accept="image/png, image/gif, image/jpeg"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileSelect}
            title="asdfadsfsd"
          />
        </Flex>
      </Flex>

      <Flex mt={5} direction={['column', 'row']}>
        <Box flex={1}>
          <Text>{t('placeHolders.yourExperience')}</Text>
          <Textarea
            className="text-fields"
            placeholder={t('placeHolders.yourExperience')}
            value={state.youBest}
            onChange={(e) => setState({ youBest: e.target.value })}
          />
        </Box>
        <Box flex={1} ml={[0, '10px']}>
          <Text>{t('placeHolders.reasonWorkInJapan')}</Text>
          <Textarea
            className="text-fields"
            placeholder={t('placeHolders.reasonWorkInJapan')}
            value={state.whyJapan}
            onChange={(e) => setState({ whyJapan: e.target.value })}
          />
        </Box>
      </Flex>

      <Flex direction={['column', 'row']}>
        <Box flex={1}>
          <Text>{t('placeHolders.strengthWeak')}</Text>
          <Textarea
            className="text-fields"
            placeholder={t('placeHolders.strengthWeak')}
            value={state.sw}
            onChange={(e) => setState({ sw: e.target.value })}
          />
        </Box>
        <Box flex={1} ml={[0, '10px']}>
          <Text>{t('placeHolders.hobbyFav')}</Text>
          <Textarea
            className="text-fields"
            placeholder={t('placeHolders.hobbyFav')}
            value={state.hobbies}
            onChange={(e) => setState({ hobbies: e.target.value })}
          />
        </Box>
      </Flex>

      <Flex direction={['column', 'row']}>
        <Box flex={0.5}>
          <Text>{t('placeHolders.goodPoints')}</Text>
          <Textarea
            className="text-fields"
            placeholder={t('placeHolders.goodPoints')}
            value={state.goodPoints}
            onChange={(e) => setState({ goodPoints: e.target.value })}
          />
        </Box>
      </Flex>

      <Flex mb={20}>
        <Box flex={1}>
          <Flex justify="flex-start">
            <Button mt="20px" className="button-primary" onClick={prevStep}>
              {t('buttons.previous')}
            </Button>
          </Flex>
        </Box>
        <Box alignItems="center" justifyContent="center" flex={1} ml="10px">
          <Flex justify="flex-end">
            <Button
              mt="20px"
              className="button-primary"
              onClick={submitFormData}
            >
              {t('buttons.submit')}
            </Button>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default OtherDataReg;
