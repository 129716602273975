import {
  Box,
  Flex,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CandidateDetails } from '../../../service/CandidateService';
import CertificationTab from './CertificationTab';
import EducationTab from './EducationTab';
import OtherTab from './OtherTab';
import PersonalTab from './PersonalTab';
import ProfessionalTab from './ProfessionalTab';

const ProfilePage = () => {
  const { t } = useTranslation();
  const [userResponse, setUserResponse] = useState<any>({});

  useEffect(() => {
    const doGet = async () => {
      await CandidateDetails().then((res: any) => {
        setUserResponse(res);
        console.log(res);
      });
    };
    doGet();
  }, []);

  return (
    <>
      <Box flex={1} margin={10}>
        <Flex>
          <Box p={4} display={{ md: 'flex' }}>
            <Box flexShrink={0}>
              <Image
                borderRadius="full"
                boxSize={150}
                src="https://bit.ly/dan-abramov"
                alt="Dan Abramov"
              />
            </Box>
            <Box flex={1}>
              <VStack justifySelf="left" align="flex-start" ml={5}>
                <h3>
                  {userResponse.firstName +
                    ' ' +
                    userResponse.middleName +
                    ' ' +
                    userResponse.lastName}
                </h3>
                <h6>{userResponse.email}</h6>
                <h6>{userResponse.contactNo}</h6>
                <h6>{userResponse.ssw}</h6>
              </VStack>
            </Box>
          </Box>
        </Flex>

        <Tabs>
          <TabList>
            <Tab>{t('Personal')}</Tab>
            <Tab>{t('Education')}</Tab>
            <Tab>{t('Certification')}</Tab>
            <Tab>{t('Professional')}</Tab>
            <Tab>{t('Other')}</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <PersonalTab userResponse={userResponse} />
            </TabPanel>
            <TabPanel>
              <EducationTab userResponse={userResponse} />
            </TabPanel>
            <TabPanel>
              <CertificationTab userResponse={userResponse} />
            </TabPanel>
            <TabPanel>
              <ProfessionalTab userResponse={userResponse} />
            </TabPanel>
            <TabPanel>
              <OtherTab userResponse={userResponse} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
};

export default ProfilePage;
