import { useTranslation } from 'react-i18next';
import { JobOpening } from '../../types/job-opening';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { getAgo } from '../../../util/Util';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { pageCandidateRegistration } from '../../../constants/StringConstants';
import { applyJobById } from '../../../service/assignment.service';

export type JobDescriptionProps = {
  job: JobOpening;
};

export const JobDescription = ({ job }: JobDescriptionProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state: RootState) => state.common.isAuthenticated
  );
  const toast = useToast();
  const [isAppling, setAppling] = useState(false);
  const [isApplied, setAppied] = useState(false);

  useEffect(() => {
    // TODO: check with the already applied jobs
    setAppied(false);
  }, [job]);

  const apply = async () => {
    if (isAppling) {
      return;
    }
    setAppling(true);
    const res = await applyJobById(job.id).catch((error) => {
      console.log(error);
      setAppling(false);
      if (error?.response?.status === 409) {
        setAppied(true);
        toast({
          title: t('Already applied for ' + job.title),
          status: 'info',
        });
      } else {
        toast({
          title: t('Error occured'),
          status: 'error',
        });
      }
    });
    if (res) {
      setAppling(false);
      setAppied(true);
      toast({
        title: t('Successfully applied for ' + job.title),
        status: 'success',
      });
    }
  };

  return (
    <>
      <Box>
        <Flex direction={'column'}>
          <Heading as={'h4'}>{job.title}</Heading>
          <Flex direction={'row'}>
            <Text>{job.placeOfEmployment}</Text>
            <Text textColor={'brown'} ml={'10px'}>{`${t('Posted ')} ${getAgo(
              job.updatedAt
            )}`}</Text>
          </Flex>
          <Text>{job.industry}</Text>
          <Text>
            {`${t('JLPT level:')} `}
            {job.jlpt > 5 ? t('Not required') : `N${job.jlpt}`}
          </Text>
          {job.salary && <Text>{`${t('Salary')} :  ${job.salary}`}</Text>}
          {isAuthenticated ? (
            <Button onClick={apply}>
              {isAppling ? <Spinner /> : isApplied ? 'Applied' : t('Apply')}
            </Button>
          ) : (
            <Button onClick={() => navigate(pageCandidateRegistration)}>
              {t('Register')}
            </Button>
          )}
          <Divider />
          <Flex direction={'column'} mt={'15px'}>
            {job.employmentStatus && (
              <Text>{`${t('Employment')} :  ${job.employmentStatus}`}</Text>
            )}
            <Heading size={'md'}>{t('Description')}</Heading>
            <Text whiteSpace={'pre-line'}>{job.description}</Text>

            <Heading size={'md'}>{t('Qualifications')}</Heading>
            <Text whiteSpace={'pre-line'}>{job.qualification}</Text>

            <Heading size={'md'}>{t('Working Hours')}</Heading>
            <Text whiteSpace={'pre-line'}>{job.workingHours}</Text>

            <Heading size={'md'}>{t('Holidays and Vacation')}</Heading>
            <Text>{job.vacation}</Text>

            <Heading size={'md'}>{t('Allowance & Bonus')}</Heading>
            <Text>{job.allowance}</Text>

            <Heading size={'md'}>{t('Insurance')}</Heading>
            <Text>{job.allowance}</Text>
            <Text>{job.allowance}</Text>

            <Heading size={'md'}>{t('Retirement Plan')}</Heading>
            <Text>{job.retirementPlan}</Text>

            <Heading size={'md'}>{t('Welfare')}</Heading>
            <Text>{job.welfare}</Text>

            <Heading size={'md'}>{t('Note')}</Heading>
            <Text>{job.note}</Text>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
