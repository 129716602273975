import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Input,
  Spinner,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { pageHome, pageLanding } from '../../constants/StringConstants';
import logoWithTextRed from '../../resources/svg/logoWithTextWhite.svg';
import { Authenticate } from '../../service/AuthService';
import { setAccessToken, setAuthenticated } from '../../storage/CookieStorage';
import { isAuthenticated } from '../redux';

type LoginForm = {
  username: string;
  password: string;
};

export const LoginPageV2 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm<LoginForm>();
  const [loginError, setLoginError] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const [isLarger] = useMediaQuery('(min-width: 800px)');

  const onSubmit = async (formData: LoginForm) => {
    if (isLoading) {
      return;
    } else {
      setIsLoading(true);
    }
    await Authenticate(formData)
      .then((res: any) => {
        setIsLoading(false);
        setAccessToken(res.token);
        setAuthenticated(true);
        navigate(pageLanding);
        isAuthenticated(true);
      })
      .catch((err) => {
        setIsLoading(false);
        setLoginError(err?.message);
      });
  };

  return (
    <Flex className="login-page-v2" direction="row">
      {isLarger && (
        <>
          <Box flex={1}>
            <Flex
              direction={'column'}
              justifyContent={'center'}
              w={'100%'}
              h={'100%'}
              alignItems={'center'}
              className="logo-section"
            >
              <img
                width="300px"
                className="img-fluid"
                src={logoWithTextRed}
                alt="React Logo"
                onClick={() => navigate(pageHome)}
              />

              <Box mt={'10px'} w={'300px'}>
                <Text
                  textAlign={'end'}
                  fontSize={'1.1em'}
                  className="slogan-text"
                >
                  Let's redefine your future
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box className="login-section" flex={1}>
            <Center h="100%">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box w="400px">
                  <Flex direction={'column'} color={'black'}>
                    <Heading>{t('buttons.login')}</Heading>
                    <Text color={'black'}>{t('titles.welcomeLogin')}</Text>
                  </Flex>
                  <Box mt="20px">
                    <Input
                      className="text-fields"
                      placeholder={t('placeHolders.email')}
                      size="lg"
                      {...register('username')}
                    />

                    <Input
                      className="text-fields"
                      mt="10px"
                      placeholder={t('placeHolders.password')}
                      size="lg"
                      type="password"
                      {...register('password')}
                    />
                  </Box>
                  {loginError && (
                    <Box mt="20px" mb="20px">
                      <Text className="error-meesage">
                        {t('errors.invalidCredentials')}
                      </Text>
                    </Box>
                  )}
                  <Box justifyContent="flex-start">
                    <Text mt="10px">{t('titles.forgotPassword')}</Text>
                  </Box>
                  <Box>
                    <Button
                      mt="20px"
                      className="button-primary"
                      type="submit"
                      w={'80px'}
                    >
                      {isLoading ? <Spinner /> : t('buttons.login')}
                    </Button>
                  </Box>
                </Box>
              </form>
            </Center>
          </Box>
        </>
      )}

      {!isLarger && (
        <>
          <Flex direction={'column'} w={'100%'} p={4}>
            <Flex
              mt={'30%'}
              mb={'20%'}
              direction={'column'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <img
                width="50%"
                className="img-fluid"
                src={logoWithTextRed}
                alt="React Logo"
                onClick={() => navigate(pageHome)}
              />

              <Box mt={'10px'} w={'300px'}>
                <Text
                  textAlign={'center'}
                  fontSize={'1.1em'}
                  className="slogan-text"
                >
                  Let's redefine your future
                </Text>
              </Box>
            </Flex>
            <Box className="login-section" p={4}>
              <Center h="100%">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Box>
                    <Flex direction={'column'} color={'black'}>
                      <Heading>{t('buttons.login')}</Heading>
                      <Text color={'black'}>{t('titles.welcomeLogin')}</Text>
                    </Flex>
                    <Box mt="20px">
                      <Input
                        className="text-fields"
                        placeholder={t('placeHolders.email')}
                        size="lg"
                        {...register('username')}
                      />

                      <Input
                        className="text-fields"
                        mt="10px"
                        placeholder={t('placeHolders.password')}
                        size="lg"
                        type="password"
                        {...register('password')}
                      />
                    </Box>
                    {loginError && (
                      <Box mt="20px" mb="20px">
                        <Text className="error-meesage">
                          {t('errors.invalidCredentials')}
                        </Text>
                      </Box>
                    )}
                    <Box justifyContent="flex-start">
                      <Text mt="10px">{t('titles.forgotPassword')}</Text>
                    </Box>
                    <Box>
                      <Button
                        mt="20px"
                        className="button-primary"
                        type="submit"
                        w={'100%'}
                      >
                        {isLoading ? <Spinner /> : t('buttons.login')}
                      </Button>
                    </Box>
                  </Box>
                </form>
              </Center>
            </Box>
          </Flex>
        </>
      )}
    </Flex>
  );
};
