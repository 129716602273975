import React from 'react';
import {
  Input,
  Button,
  Box,
  Flex,
  Center,
  Heading,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Authenticate } from '../../service/AuthService';
import useMergeState from '../hooks/useMergeState';
import { valueIsEmpty, valueIsEmail } from '../../util/Util';
import {
  hideLoading,
  isAuthenticated,
  showAlertModal,
  showLoading,
} from '../redux';
import { useNavigate } from 'react-router-dom';
import { pageLanding } from '../../constants/StringConstants';
import { setAccessToken, setAuthenticated } from '../../storage/CookieStorage';

const LoginPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [state, setState] = useMergeState({
    userName: '',
    password: '',
    userNameError: '',
    passwordError: '',
  });

  const onForgotPasswordClick = () => {
    console.log('Forgot password clicked');
  };

  const onLoginClick = async () => {
    if (validateInputs()) {
      const reqBody = {
        username: state.userName,
        password: state.password,
      };
      showLoading();
      await Authenticate(reqBody)
        .then((res: any) => {
          setAccessToken(res.token);
          setAuthenticated(true);
          hideLoading();
          navigate(pageLanding);
          isAuthenticated(true);
        })
        .catch((err) => {
          console.log('Logn error - ', err);
          setTimeout(() => {
            hideLoading();
            showAlertModal('error', 'Login failed');
          }, 2000);
        });
    }
  };

  const validateInputs = () => {
    let isValid = true;
    if (valueIsEmpty(state.userName)) {
      setState({ userNameError: 'errors.emailRequired' });
      isValid = false;
    } else if (!valueIsEmail(state.userName)) {
      setState({ userNameError: 'errors.emailInvalid' });
      isValid = false;
    } else {
      setState({ userNameError: '' });
    }
    if (valueIsEmpty(state.password)) {
      setState({ passwordError: 'errors.passwordRequired' });
      isValid = false;
    } else {
      setState({ passwordError: '' });
    }
    return isValid;
  };

  return (
    <Flex className="login-page" direction="row">
      <Box flex={1}></Box>
      <Box className="login-section" flex={1}>
        <Center h="100%">
          <Box w="400px">
            <Box>
              <Heading>{t('buttons.login')}</Heading>
              <Text>{t('titles.welcomeLogin')}</Text>
            </Box>
            <Box mt="20px">
              <Input
                className="text-fields"
                placeholder={t('placeHolders.email')}
                size="lg"
                value={state.userName}
                onChange={(e) => setState({ userName: e.target.value })}
              />
              {state.userNameError !== '' ? (
                <Text className="input-error-message">
                  {t(state.userNameError)}
                </Text>
              ) : (
                <></>
              )}
              <Input
                className="text-fields"
                mt="10px"
                placeholder={t('placeHolders.password')}
                size="lg"
                type="password"
                value={state.password}
                onChange={(e) => setState({ password: e.target.value })}
              />
              {state.passwordError !== '' ? (
                <Text className="input-error-message">
                  {t(state.passwordError)}
                </Text>
              ) : (
                <></>
              )}
            </Box>
            <Box justifyContent="flex-start">
              <Text mt="10px" onClick={onForgotPasswordClick}>
                {t('titles.forgotPassword')}
              </Text>
            </Box>
            <Box>
              <Button
                mt="20px"
                className="button-primary"
                onClick={onLoginClick}
              >
                {t('buttons.login')}
              </Button>
            </Box>
          </Box>
        </Center>
      </Box>
    </Flex>
  );
};

export default LoginPage;
