import React from 'react';
import {
  Box,
  Flex,
  Icon,
  Link,
} from '@chakra-ui/react';
import { FaSignOutAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';

interface RootState {
  common: {
    isAuthenticated: boolean
  }
}

const Navbar = () => {
  const isAuthenticated = useSelector((state: RootState) => state.common.isAuthenticated)

  if (isAuthenticated) {
    return (
      <Box className='nav-bar' p={4}>
        <Flex justifyContent='right'>
          <Link href="/logout" alignSelf='flex-end' justifySelf='right'>
            <Icon as={FaSignOutAlt} mr={2} />
            Logout
          </Link>
        </Flex>
      </Box>
    );
  } else {
    <></>
  }
};

export default Navbar;