import { ReactNode } from 'react';
import { SearchPage } from '../components/pages/SearchPage';
import React from 'react';
import LoginPage from '../components/pages/LoginPage';
import LogoutPage from '../components/pages/LogoutPage';
import LandingPage from '../components/pages/LandingPage';
import ProfilePage from '../components/pages/profile/ProfilePage';
import { Route } from 'react-router-dom';
import CandidateRegistrationPage from '../components/pages/candidateRegistration/CandidateRegistrationPage';
import {
  pageCandidateRegistration,
  pageLogin,
  pageLogout,
  pageProfile,
} from './StringConstants';
import { LoginPageV2 } from '../components/pages/LoginPageV2';

export type RouteItem = {
  path: string;
  name?: string;
  component?: unknown;
  element?: ReactNode;
  icon?: unknown;
  category?: string;
  subItems?: RouteItem[];
};

export const routeItems: RouteItem[] = [
  {
    path: '/',
    element: <SearchPage />,
  },
  {
    path: `${pageLogin}-old`,
    element: <LoginPage />,
  },
  {
    path: pageLogin,
    element: <LoginPageV2 />,
  },
  {
    path: pageCandidateRegistration,
    element: <CandidateRegistrationPage />,
  },
  {
    path: pageLogout,
    element: <LogoutPage />,
  },
  {
    path: pageProfile,
    category: 'Profile',
    element: <LandingPage />,
    subItems: [
      {
        path: '/',
        element: <ProfilePage />,
      },
    ],
  },
];

export const getRouts = (
  sideBarNavItems: RouteItem[],
  parentPath?: string
): ReactNode[] => {
  return sideBarNavItems.map((item: RouteItem) => {
    if (item.subItems) {
      return (
        <Route
          key={item.name}
          path={parentPath ? parentPath + item.path : item.path}
          element={item.element}
        >
          {getRouts(item.subItems as RouteItem[], item.path)}
        </Route>
      );
    }
    return (
      <Route
        key={item.name}
        path={parentPath ? parentPath + item.path : item.path}
        element={item.element}
      ></Route>
    );
  });
};
