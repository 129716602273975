import { setAlertModal, setIsAuthenticated, setLoadingIndicatorVisible } from "./CommonReducer"
import Store from "./Store"

export const showLoading = () => {
    Store.dispatch(setLoadingIndicatorVisible(true))
}

export const hideLoading = () => {
    Store.dispatch(setLoadingIndicatorVisible(false))
}

export const showAlertModal = (alertType: 'success' | 'error' | 'warning' | 'info', alertMessage: string) => {
    Store.dispatch(setAlertModal({visible: true, type: alertType, message: alertMessage}))
}

export const hideAlertModal = () => {
    Store.dispatch(setAlertModal({ }))
}

export const isAuthenticated = (authenticated: boolean) => {
    Store.dispatch(setIsAuthenticated(authenticated))
}