import Cookies from 'js-cookie'

export const getAccessToken = () => Cookies.get('access_token')

export const setAccessToken = (accessToken: string) => {
    const expires = (60 * 60) * 1000
    const inOneHour = new Date(new Date().getTime() + expires)
    Cookies.set('access_token', accessToken, { expires: inOneHour })
}

export const getRefreshToken = () => Cookies.get('refresh_token')

export const setAuthenticated = (authentic: boolean) =>  Cookies.set('authenticated', authentic.toString())

export const getAuthenticated = () => Cookies.get('authenticated')

export const removeCookies = () => {
    Cookies.remove('access_token')
    Cookies.remove('authenticated')
}
