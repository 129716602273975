import { formatDistanceToNow, parseISO } from 'date-fns';

export const createLog = (message: any) => {
  console.log(message);
};

export const valueIsEmpty = (value: string) => {
  if (value === undefined) {
    return true;
  }
  if (value === '') {
    return true;
  }
};

export const valueIsEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const getAgo = (date: string) => {
  const parsedDate = parseISO(date);
  return formatDistanceToNow(parsedDate, { addSuffix: true });
};
