import React from 'react';
import { useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Input,
  Text,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import useMergeState from '../../hooks/useMergeState';
import { valueIsEmpty } from '../../../util/Util';
import { DeleteIcon } from '@chakra-ui/icons';

interface professionalDatProps {
  nextStep: () => void;
  prevStep: () => void;
  setProfessionalData: (ProfessionalData: ProfessionalDataObject[]) => void;
  professionalData: ProfessionalDataObject[];
}

const ProfessionalDataReg = ({
  nextStep,
  prevStep,
  setProfessionalData,
  professionalData,
}: professionalDatProps) => {
  const { t } = useTranslation();
  const [state, setState] = useMergeState({
    company: '',
    title: '',
    startDate: '',
    endDate: '',
    companyError: '',
    titleError: '',
    startDateError: '',
  });
  const [professionalInfo, setProfessionalInfo] = useState(professionalData);

  // after form submit validating the form data using validator
  const submitFormData = (e: any) => {
    e.preventDefault();
    if (professionalInfo.length > 0) {
      setProfessionalData(professionalInfo);
    }
    nextStep();
  };

  const validateInputs = () => {
    let isValid = true;
    if (valueIsEmpty(state.company)) {
      setState({ companyError: 'required' });
      isValid = false;
    } else {
      setState({ companyError: '' });
    }
    if (valueIsEmpty(state.title)) {
      setState({ titleError: 'required' });
    } else {
      setState({ titleError: '' });
    }
    if (valueIsEmpty(state.startDate)) {
      setState({ startDateError: 'required' });
      isValid = false;
    } else {
      setState({ startDateError: '' });
    }
    return isValid;
  };

  const addOnClick = () => {
    if (validateInputs()) {
      const profInfo = {
        company: state.company,
        title: state.company,
        startDate: state.startDate,
        endDate: state.endDate,
        isPresent: true,
      };
      professionalInfo.push(profInfo);
      setProfessionalInfo(professionalInfo);
      setState({ company: '', title: '', startDate: '', endDate: '' });
    }
  };

  const removeOnClick = (item: ProfessionalDataObject) => {
    const arr = professionalInfo.filter((x) => x !== item);
    setProfessionalInfo(arr);
  };

  return (
    <>
      <Heading>{t('titles.professionalInformation')}</Heading>
      <Flex direction={['column', 'row']}>
        <Box flex={1}>
          <Text>{t('placeHolders.company')}</Text>
          <Input
            className="text-fields"
            placeholder={t('placeHolders.company')}
            size="md"
            value={state.company}
            onChange={(e) => setState({ company: e.target.value })}
          />
          {state.companyError !== '' ? (
            <Text className="input-error-message">{t(state.companyError)}</Text>
          ) : (
            <></>
          )}
        </Box>
        <Box flex={1} ml={[0, '10px']}>
          <Text>{t('placeHolders.positionHeld')}</Text>
          <Input
            className="text-fields"
            placeholder={t('placeHolders.positionHeld')}
            size="md"
            value={state.title}
            onChange={(e) => setState({ title: e.target.value })}
          />
          {state.titleError !== '' ? (
            <Text className="input-error-message">{t(state.titleError)}</Text>
          ) : (
            <></>
          )}
        </Box>
      </Flex>
      <Flex direction={['column', 'row']}>
        <Box flex={1}>
          <Text>{t('placeHolders.startDate')}</Text>
          <Input
            type="date"
            className="text-fields"
            placeholder={t('placeHolders.startDate')}
            size="md"
            value={state.startDate}
            onChange={(e) => setState({ startDate: e.target.value })}
          />
          {state.startDateError !== '' ? (
            <Text className="input-error-message">
              {t(state.startDateError)}
            </Text>
          ) : (
            <></>
          )}
        </Box>
        <Box flex={1} ml={[0, '10px']}>
          <Text>{t('placeHolders.endDate')}</Text>
          <Input
            type="date"
            className="text-fields"
            placeholder={t('placeHolders.endDate')}
            size="md"
            value={state.endDate}
            onChange={(e) => setState({ endDate: e.target.value })}
          />
        </Box>
      </Flex>
      <Flex>
        <Box flex={1} mr="10px">
          <Flex justify="center">
            <Button mt="20px" className="button-secondary" onClick={addOnClick}>
              {t('buttons.add')}
            </Button>
          </Flex>
        </Box>
      </Flex>

      <Flex mt={5} w={'100%'}>
        <Box flex={1} backgroundColor={'white'} overflowX={'auto'}>
          <TableContainer>
            <Table variant="simple">
              {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
              <Thead>
                <Tr>
                  <Th>{t('placeHolders.company')}</Th>
                  <Th>{t('placeHolders.positionHeld')}</Th>
                  <Th>{t('placeHolders.startDate')}</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              {professionalInfo.length > 0 ? (
                <Tbody>
                  {professionalInfo.map((item, index) => {
                    return (
                      <Tr key={index}>
                        <Td>{item.company}</Td>
                        <Td>{item.title}</Td>
                        <Td>{item.startDate}</Td>
                        <Td>
                          <DeleteIcon
                            w={5}
                            h={5}
                            color="red.500"
                            onClick={() => removeOnClick(item)}
                          />
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              ) : (
                <Tbody>
                  {' '}
                  <Tr>{t('errors.noRecords')}</Tr>{' '}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Box>
      </Flex>
      <Flex mb={5}>
        <Box flex={1} mr="10px">
          <Flex justify="flex-start">
            <Button mt="20px" className="button-primary" onClick={prevStep}>
              {t('buttons.previous')}
            </Button>
          </Flex>
        </Box>
        <Box alignItems="center" justifyContent="center" flex={1} ml="10px">
          <Flex justify="flex-end">
            <Button
              mt="20px"
              className="button-primary"
              onClick={submitFormData}
            >
              {t('buttons.next')}
            </Button>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default ProfessionalDataReg;
