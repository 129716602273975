import {
  Box,
  Button,
  Divider,
  Flex,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  pageHome,
  pageLanding,
  pageLogin,
} from '../../../constants/StringConstants';
import { URL_PROFILE_PIC_UPLOAD } from '../../../constants/UrlConstants';
import { CandidateRegister } from '../../../service/CandidateService';
import { uploadImage } from '../../../util/ServiceUtils';
import useMergeState from '../../hooks/useMergeState';
import { hideLoading, showAlertModal, showLoading } from '../../redux';
import logoWithTextRed from './../../../resources/svg/logoWithTextRed.svg';
import CertificationDataReg from './CertificationDataReg';
import EducationalDataReg from './EducationalDataReg';
import OtherDataReg from './OtherDataReg';
import PersonalDataReg from './PersonalDataReg';
import ProfessionalDataReg from './ProfessionalDataReg';
import { EduInfoObject } from '../../types/EduInfoObject';

const CandidateRegistrationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLarger] = useMediaQuery('(min-width: 800px)');

  const [step, setstep] = useState(0);
  const [state, setState] = useMergeState({
    user: {},
    educations: [],
    work: [],
    certifications: [],
    otherData: {},
  });

  const steps = [
    { title: t('stepper.first'), description: t('stepper.personalInfo') },
    { title: t('stepper.second'), description: t('stepper.educationalInfo') },
    { title: t('stepper.third'), description: t('stepper.professionalInfo') },
    { title: t('stepper.fourth'), description: t('stepper.certificationInfo') },
    { title: t('stepper.fifth'), description: t('stepper.otherInfo') },
  ];

  const setPersonalData = (personalData: {}) => {
    setState({ user: personalData });
  };

  const setEducationalData = (educationalData: EduInfoObject[]) => {
    setState({ educations: educationalData });
  };

  const setProfessionalData = (professionalData: ProfessionalDataObject[]) => {
    setState({ work: professionalData });
  };

  const setCertificationData = (
    certificationData: CertificationDataObject[]
  ) => {
    setState({ certifications: certificationData });
  };

  const setOtherData = (otherData: OtherRegDataObject) => {
    setState({ otherData: otherData });
    submitData(otherData);
  };

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setstep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setstep(step - 1);
  };

  const submitData = async (otherData: OtherRegDataObject) => {
    // Upload profile image first if available

    showLoading();
    let imageFileName = '';
    if (otherData.imageFile) {
      console.log('Uploading image');
      const imageResponse: any = await uploadImage(
        otherData.imageFile,
        URL_PROFILE_PIC_UPLOAD
      );
      imageFileName = imageResponse.data.fileName as string;
    }
    const reqBody = {
      user: {
        auth: {
          username: state.user.username,
          password: state.user.password,
        },
        user: {
          firstName: state.user.firstName,
          middleName: state.user.middleName,
          lastName: state.user.lastName,
          email: state.user.email,
          mobile: `${state.user.countryCode}-${state.user.contactNo}`,
        },
      },
      candidate: {
        firstName: state.user.firstName,
        lastName: state.user.lastName,
        middleName: state.user.middleName,
        picture: imageFileName,
        nationality: state.user.nationality,
        dob: state.user.dob,
        address: state.user.address,
        country: state.user.country,
        sex: state.user.gender,
        periodOfStay: state.user.periodofStay,
        ssw: state.user.ssw,
        statusOfResidence: state.user.statusOfResidence,
        japaneseProficiency: state.user.japaneseProficiency,
        jlptComplatedOn: state.user.jlptComplatedOn,
        whyJapan: otherData.whyJapan,
        youBest: otherData.youBest,
        sw: otherData.sw,
        hobbies: otherData.hobbies,
        goodPoints: otherData.goodPoints,
        educations: state.educations,
        work: state.work,
        certifications: state.certifications,
        skils: [],
        note: '',
        fullNameInJp: state.user.fullNameInJp,
        fullName: state.user.fullName,
        postalCode: state.user.postalCode,
      },
    };
    await CandidateRegister(reqBody)
      .then((res) => {
        hideLoading();
        navigate(pageLanding);
      })
      .catch((err) => {
        hideLoading();
        showAlertModal('error', err);
      });
  };

  return (
    <Flex className="register-page" direction={'column'}>
      <Flex
        p={'15px'}
        w={'100%'}
        justifyContent="space-between"
        className="header-bar"
      >
        <img
          src={logoWithTextRed}
          width={'150px'}
          alt="logo"
          onClick={() => navigate(pageHome)}
        />
        <Flex>
          <Button variant="ghost" onClick={() => navigate(pageLogin)}>
            {t('Login')}
          </Button>
        </Flex>
      </Flex>
      <Flex direction={'column'} alignItems={'center'}>
        <Box className="register-section" w={['100%', '8xl']} mb="30px">
          <Flex justifyContent="center">
            <Box w="80%">
              <Stepper
                size={['sm', 'md']}
                index={step}
                mt={20}
                colorScheme="red"
              >
                {steps.map((stepItem, index) => (
                  <Step key={index}>
                    <StepIndicator>
                      <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>

                    {isLarger && (
                      <Box flexShrink="0">
                        <StepTitle>{stepItem.title}</StepTitle>
                        <StepDescription>
                          {stepItem.description}
                        </StepDescription>
                      </Box>
                    )}

                    <StepSeparator />
                  </Step>
                ))}
              </Stepper>
              <Divider mb={4} />
              {step === 0 ? (
                <PersonalDataReg
                  nextStep={nextStep}
                  setPersonalData={setPersonalData}
                  personalData={state.user}
                />
              ) : step === 1 ? (
                <EducationalDataReg
                  nextStep={nextStep}
                  prevStep={prevStep}
                  setEducationalData={setEducationalData}
                  educationalData={state.educations}
                />
              ) : step === 2 ? (
                <ProfessionalDataReg
                  nextStep={nextStep}
                  prevStep={prevStep}
                  setProfessionalData={setProfessionalData}
                  professionalData={state.work}
                />
              ) : step === 3 ? (
                <CertificationDataReg
                  nextStep={nextStep}
                  prevStep={prevStep}
                  setCertificationData={setCertificationData}
                  certificationData={state.certifications}
                />
              ) : step === 4 ? (
                <OtherDataReg
                  prevStep={prevStep}
                  setOtherData={setOtherData}
                  otherData={state.otherData}
                />
              ) : (
                <></>
              )}
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default CandidateRegistrationPage;

// {
//   "user": {
//     "auth": {
//       "username": "string",
//       "password": "string"
//     },
//     "user": {
//       "firstName": "string",
//       "lastName": "string",
//       "email": "string",
//       "mobile": "string",
//       "createdAt": "2023-07-16T15:58:11.741Z",
//       "updatedAt": "2023-07-16T15:58:11.741Z"
//     }
//   },
//   "candidate": {
//     "userId": 0,
//     "fullName": "string",
//     "fistName": "string",
//     "lastName": "string",
//     "middleName": "string",
//     "picture": "string",
//     "educations": [
//       {
//         "institute": "USCS",
//         "qualification": "BSc",
//         "startDate": "string",
//         "endDate": "string",
//         "isReading": true
//       }
//     ],
//     "work": [
//       {
//         "company": "string",
//         "title": "string",
//         "startDate": "string",
//         "endDate": "string",
//         "isPresent": true,
//         "bgvNote": "string",
//         "bgvStatus": "NOT_STARTED",
//         "isBgvComplated": true
//       }
//     ],
//     "certifications": [
//       {
//         "title": "string",
//         "issuedBy": "string",
//         "startDate": "string",
//         "expirationDate": "string",
//         "credentialUrl": "string",
//         "credentialId": "string"
//       }
//     ],
//     "skils": [
//       {
//         "name": "string"
//       }
//     ],
//     "note": "string"
//   }
// }
