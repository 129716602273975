import { createSlice } from '@reduxjs/toolkit'

export const CommonSlice = createSlice({
    name: 'commonSlice',
    initialState: {
        loadingIndicatorVisible: false,
        alertModel: {},
        isAuthenticated: false
    },
    reducers: {
        setLoadingIndicatorVisible: (state, action) => {
            state.loadingIndicatorVisible = action.payload
        },
        setAlertModal: (state, action) => {
            state.alertModel = action.payload
        },
        setIsAuthenticated: (state, action) => {
            state.isAuthenticated = action.payload
        }
    }
})

export const { setLoadingIndicatorVisible, setAlertModal, setIsAuthenticated } = CommonSlice.actions

export default CommonSlice.reducer