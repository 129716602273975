import React, { useEffect } from "react"
import { isAuthenticated } from "../redux"
import { removeCookies, setAuthenticated } from "../../storage/CookieStorage"
import { useNavigate } from "react-router-dom"
import { pageLanding } from "../../constants/StringConstants"

const LogoutPage = () => {
    const navigate = useNavigate()
    useEffect(() => {
        isAuthenticated(false)
        removeCookies()
        navigate(pageLanding);
    },[])

    return(
        <></>
    )
}

export default LogoutPage