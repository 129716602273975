import { URL_CANDIDATE_DETAILS, URL_CANDIDATE_REGISTER } from "../constants/UrlConstants";
import { apiRequest } from "../util/ServiceUtils";
import { createLog } from "../util/Util";

export const CandidateRegister = (body: object) => {
    return new Promise(async (resolve, reject) => {
        
        await apiRequest('POST', URL_CANDIDATE_REGISTER, body)
        .then((res) => {
            resolve(res)
        })
        .catch((err) => {
            createLog(err)
            reject(err)
        })
    });
}

export const CandidateDetails = () => {
    return new Promise(async (resolve, reject) => {
        
        await apiRequest('GET', URL_CANDIDATE_DETAILS, {})
        .then((res) => {
            resolve(res)
        })
        .catch((err) => {
            createLog(err)
            reject(err)
        })
    });
}